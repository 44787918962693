"use client";
import { cn } from "../../lib/utils";

function Logo({ className, ...props }) {
  return (
    <svg
      width="163"
      height="46"
      viewBox="0 0 356 60"
      className={cn(className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M126.022 38.0934C126.022 41.1631 124.921 43.5817 122.718 45.3492C120.53 47.1166 117.48 48.0003 113.567 48.0003C109.962 48.0003 106.773 47.3182 104 45.9538V39.2562C106.28 40.2794 108.206 41.0003 109.777 41.4189C111.364 41.8375 112.812 42.0468 114.122 42.0468C115.693 42.0468 116.895 41.7445 117.726 41.1399C118.574 40.5352 118.997 39.636 118.997 38.4422C118.997 37.7755 118.813 37.1864 118.443 36.6748C118.073 36.1476 117.526 35.6437 116.802 35.1631C116.093 34.6825 114.638 33.9151 112.435 32.8608C110.37 31.8841 108.822 30.9461 107.79 30.0468C106.758 29.1476 105.933 28.1011 105.317 26.9073C104.701 25.7135 104.393 24.3182 104.393 22.7213C104.393 19.7135 105.402 17.3492 107.42 15.6282C109.454 13.9073 112.257 13.0468 115.832 13.0468C117.588 13.0468 119.259 13.2561 120.846 13.6748C122.448 14.0934 124.12 14.6825 125.861 15.4422L123.55 21.0468C121.747 20.3027 120.253 19.7833 119.067 19.4887C117.896 19.1941 116.74 19.0468 115.6 19.0468C114.245 19.0468 113.205 19.3647 112.481 20.0003C111.757 20.636 111.395 21.4655 111.395 22.4887C111.395 23.1244 111.541 23.6825 111.834 24.1631C112.126 24.6282 112.589 25.0856 113.22 25.5352C113.867 25.9693 115.385 26.76 117.773 27.9073C120.931 29.4267 123.095 30.9538 124.266 32.4887C125.437 34.0081 126.022 35.8763 126.022 38.0934Z"
        fill={props?.color ? props?.color : "black"}
      />
      <path
        d="M140.73 29.5352H143.087C145.29 29.5352 146.938 29.1011 148.032 28.2329C149.126 27.3492 149.673 26.0701 149.673 24.3957C149.673 22.7058 149.21 21.4577 148.286 20.6515C147.377 19.8453 145.944 19.4422 143.988 19.4422H140.73V29.5352ZM156.906 24.1399C156.906 27.7988 155.765 30.5972 153.485 32.5352C151.221 34.4732 147.993 35.4422 143.803 35.4422H140.73V47.5352H133.566V13.5352H144.358C148.456 13.5352 151.567 14.4267 153.693 16.2096C155.835 17.9771 156.906 20.6205 156.906 24.1399Z"
        fill={props?.color ? props?.color : "black"}
      />
      <path
        d="M195.368 30.4887C195.368 36.1166 193.982 40.4422 191.209 43.4655C188.436 46.4887 184.461 48.0003 179.285 48.0003C174.108 48.0003 170.134 46.4887 167.361 43.4655C164.588 40.4422 163.201 36.1011 163.201 30.4422C163.201 24.7833 164.588 20.4655 167.361 17.4887C170.149 14.4965 174.139 13.0003 179.331 13.0003C184.523 13.0003 188.49 14.5042 191.232 17.512C193.989 20.5197 195.368 24.8453 195.368 30.4887ZM170.711 30.4887C170.711 34.2872 171.428 37.1476 172.861 39.0701C174.293 40.9926 176.435 41.9538 179.285 41.9538C185 41.9538 187.858 38.1321 187.858 30.4887C187.858 22.8298 185.016 19.0003 179.331 19.0003C176.481 19.0003 174.332 19.9693 172.884 21.9073C171.436 23.8298 170.711 26.6903 170.711 30.4887Z"
        fill={props?.color ? props?.color : "black"}
      />
      <path
        d="M233.762 47.5352H224.657L209.96 21.8143H209.752C210.045 26.3569 210.191 29.5972 210.191 31.5352V47.5352H203.79V13.5352H212.825L227.499 39.0003H227.661C227.43 34.5817 227.314 31.4577 227.314 29.6282V13.5352H233.762V47.5352Z"
        fill={props?.color ? props?.color : "black"}
      />
      <path
        d="M271.739 30.2096C271.739 35.8065 270.152 40.0934 266.979 43.0701C263.82 46.0469 259.253 47.5352 253.275 47.5352H243.708V13.5352H254.315C259.83 13.5352 264.113 15.0003 267.164 17.9306C270.214 20.8608 271.739 24.9538 271.739 30.2096ZM264.298 30.3957C264.298 23.0934 261.094 19.4422 254.685 19.4422H250.872V41.5817H253.945C260.847 41.5817 264.298 37.8531 264.298 30.3957Z"
        fill={props?.color ? props?.color : "black"}
      />
      <path
        d="M299.618 47.5352H280.161V13.5352H299.618V19.4422H287.324V26.9073H298.763V32.8143H287.324V41.5817H299.618V47.5352Z"
        fill={props?.color ? props?.color : "black"}
      />
      <path
        d="M327.566 47.5352H308.109V13.5352H327.566V19.4422H315.273V26.9073H326.711V32.8143H315.273V41.5817H327.566V47.5352Z"
        fill={props?.color ? props?.color : "black"}
      />
      <path
        d="M356 38.0934C356 41.1631 354.898 43.5817 352.695 45.3492C350.508 47.1166 347.458 48.0003 343.545 48.0003C339.94 48.0003 336.751 47.3182 333.978 45.9538V39.2562C336.258 40.2794 338.183 41.0003 339.755 41.4189C341.342 41.8375 342.79 42.0468 344.099 42.0468C345.67 42.0468 346.872 41.7445 347.704 41.1399C348.551 40.5352 348.975 39.636 348.975 38.4422C348.975 37.7755 348.79 37.1864 348.42 36.6748C348.051 36.1476 347.504 35.6437 346.78 35.1631C346.071 34.6825 344.615 33.9151 342.412 32.8608C340.348 31.8841 338.8 30.9461 337.767 30.0468C336.735 29.1476 335.911 28.1011 335.295 26.9073C334.679 25.7135 334.37 24.3182 334.37 22.7213C334.37 19.7135 335.38 17.3492 337.398 15.6282C339.431 13.9073 342.235 13.0468 345.809 13.0468C347.565 13.0468 349.237 13.2561 350.824 13.6748C352.426 14.0934 354.097 14.6825 355.838 15.4422L353.527 21.0468C351.725 20.3027 350.231 19.7833 349.044 19.4887C347.873 19.1941 346.718 19.0468 345.578 19.0468C344.222 19.0468 343.182 19.3647 342.458 20.0003C341.734 20.636 341.372 21.4655 341.372 22.4887C341.372 23.1244 341.519 23.6825 341.811 24.1631C342.104 24.6282 342.566 25.0856 343.198 25.5352C343.845 25.9693 345.362 26.76 347.75 27.9073C350.908 29.4267 353.073 30.9538 354.244 32.4887C355.415 34.0081 356 35.8763 356 38.0934Z"
        fill={props?.color ? props?.color : "black"}
      />
      <rect
        width="56.4218"
        height="20.7485"
        rx="10.3742"
        transform="matrix(0.759221 -0.650833 0.638169 0.769897 0 41.8929)"
        fill="#FF980D"
      />
      <path
        d="M42.4702 46.3224C38.7779 41.8697 39.3455 35.2089 43.7378 31.445L70.6759 8.36174C75.0683 4.59788 81.6222 5.15631 85.3145 9.60903C89.0068 14.0617 88.4393 20.7226 84.0469 24.4864L57.1088 47.5697C52.7165 51.3336 46.1625 50.7752 42.4702 46.3224Z"
        fill="#37D8F2"
      />
      <path
        d="M61.4541 16.1964C57.8495 11.9012 52.7154 9.23631 47.1814 8.78797C41.6474 8.33963 36.1666 10.1446 31.9449 13.8057C27.7232 17.4669 25.1064 22.6844 24.67 28.3104C24.2337 33.9364 26.0136 39.5101 29.6182 43.8054L45.5362 30.0009L61.4541 16.1964Z"
        fill="#FF3360"
      />
      <path
        d="M29.5555 43.7267C33.1435 48.0288 38.2658 50.7057 43.7955 51.1686C49.3251 51.6315 54.8092 49.8425 59.0413 46.195C63.2733 42.5475 65.9067 37.3405 66.3621 31.7193C66.8174 26.0981 65.0575 20.5232 61.4694 16.2211L45.5124 29.9739L29.5555 43.7267Z"
        fill="#4D92E0"
      />
    </svg>
  );
}

export { Logo };
