import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import ScrollToTop from "./components/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  // useEffect(() => {
  //   if (location?.pathname !== ROUTE.BOOK_SEARCH) {
  //     context.setSearch("");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  // useEffect(() => {
  //   checkUserLogin();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const checkUserLogin = async () => {
  //   const user = await getFromAsync(SESSION_KEY.USER_DATA);
  //   if (user?.GetNewPassword !== undefined && !user?.GetNewPassword) {
  //     if (location.pathname === ROUTE?.LOGIN) {
  //       navigate(ROUTE?.DASHBOARD);
  //     }
  //   } else {
  //     navigate(ROUTE?.LOGIN);
  //   }
  // };

  return (
    <div className="App">
      <ToastContainer />
      <>
        <div className="w-full">
          <Header />
          <Navigation />
          <Footer />

          <ScrollToTop />
        </div>
      </>
    </div>
  );
}

export default App;
