'use client'
import { MoveUp } from 'lucide-react'
import React, { useState, useEffect } from 'react'

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [scrollProgress, setScrollProgress] = useState(0)

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const trackScrollProgress = () => {
    const scrolled = document.documentElement.scrollTop
    const totalHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    const scrollPercentage = (scrolled / totalHeight) * 100
    setScrollProgress(scrollPercentage)
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    toggleVisibility() // Check visibility on mount
    window.addEventListener('scroll', toggleVisibility)
    window.addEventListener('scroll', trackScrollProgress)

    return () => {
      window.removeEventListener('scroll', toggleVisibility)
      window.removeEventListener('scroll', trackScrollProgress)
    }
  }, [])

  return (
    <div className="fixed bottom-5 right-5 z-50">
      {isVisible && (
        <div
          className="relative flex items-center justify-center w-14 h-14 bg-black text-white rounded-full cursor-pointer"
          onClick={scrollToTop}
        >
          <svg
            className="absolute inset-0 w-full h-full rounded-full"
            style={{ transform: 'rotate(-90deg)' }}
            viewBox="0 0 56 56"
          >
            <circle
              className="text-gray-700"
              stroke="currentColor"
              strokeWidth="4"
              fill="transparent"
              r="24"
              cx="28"
              cy="28"
            />
            <circle
              className="text-blue-500"
              stroke="currentColor"
              strokeWidth="4"
              strokeDasharray="150.72"
              strokeDashoffset={150.72 - (150.72 * scrollProgress) / 100}
              fill="transparent"
              r="24"
              cx="28"
              cy="28"
            />
          </svg>
          <MoveUp className="w-6 h-6 text-white" />
        </div>
      )}
    </div>
  )
}

export default ScrollToTop
