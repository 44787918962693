import { forwardRef, useEffect, useState } from "react";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "./ui/navigation-menu";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetDescription,
} from "./ui/sheet";
import { Logo } from "./ui/icons";
import { cn } from "../lib/utils";
import { AlignLeft } from "lucide-react";
import ROUTES from "../services/AppConstant";
import { useLocation } from "react-router-dom";

const Header = () => {
  const ListItem = forwardRef(
    ({ className, title, children, ...props }, ref) => (
      <li>
        <NavigationMenuLink asChild>
          <a
            ref={ref}
            className={cn(
              "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className
            )}
            {...props}
          >
            <div className="text-sm font-medium leading-none">{title}</div>
            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
              {children}
            </p>
          </a>
        </NavigationMenuLink>
      </li>
    )
  );
  ListItem.displayName = "ListItem";
  let location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const { pathname } = location;
  const transperentHeader = ["/", "/searchApi"];

  useEffect(() => {
    const handleScroll = () => {
      if (transperentHeader?.includes(pathname)) {
        setScrolled(window.scrollY > 300);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // router.refresh();
    setIsSheetOpen(false);
  }, [pathname]);

  return (
    <div className="fixed top-0 left-0 w-full shadow-header p-5  z-[1030] bg-[rgba(255, 255, 255, 0.7)] backdrop-blur-xl">
      <div className="max-w-7xl m-auto flex justify-between items-center">
        <Logo
          color={
            scrolled || !transperentHeader?.includes(pathname)
              ? "black"
              : "white"
          }
        />
        <NavigationMenu className="hidden lg:block">
          <NavigationMenuList>
            <NavigationMenuItem className={`px-5`}>
              <a href={ROUTES?.HOME} legacyBehavior passHref>
                <NavigationMenuLink
                  className={
                    (navigationMenuTriggerStyle(),
                    ` ${
                      scrolled || !transperentHeader?.includes(pathname)
                        ? "text-dark-gray-1 font-semibold"
                        : "text-white"
                    } bg-transparent hover:bg-transparent text-lg hover:underline `)
                  }
                >
                  Home
                </NavigationMenuLink>
              </a>
            </NavigationMenuItem>
            <NavigationMenuItem className="px-5">
              <a href="/#features" legacyBehavior passHref>
                <NavigationMenuLink
                  className={
                    (navigationMenuTriggerStyle(),
                    ` ${
                      scrolled || !transperentHeader?.includes(pathname)
                        ? "text-dark-gray-1 font-semibold"
                        : "text-white"
                    } bg-transparent hover:bg-transparent text-lg hover:underline ${
                      pathname === "/#features" &&
                      "!text-transparent bg-clip-text bg-custom-gradient"
                    }`)
                  }
                >
                  Features
                </NavigationMenuLink>
              </a>
            </NavigationMenuItem>
            <NavigationMenuItem className="px-5">
              <a href="/#about" legacyBehavior passHref>
                <NavigationMenuLink
                  className={
                    (navigationMenuTriggerStyle(),
                    ` ${
                      scrolled || !transperentHeader?.includes(pathname)
                        ? "text-dark-gray-1 font-semibold"
                        : "text-white"
                    } bg-transparent hover:bg-transparent text-lg hover:underline`)
                  }
                >
                  About Us
                </NavigationMenuLink>
              </a>
            </NavigationMenuItem>
            <NavigationMenuItem className="px-5">
              <a href="/#question-and-answer" legacyBehavior passHref>
                <NavigationMenuLink
                  className={
                    (navigationMenuTriggerStyle(),
                    ` ${
                      scrolled || !transperentHeader?.includes(pathname)
                        ? "text-dark-gray-1 font-semibold"
                        : "text-white"
                    } bg-transparent hover:bg-transparent text-lg hover:underline`)
                  }
                >
                  Q&A
                </NavigationMenuLink>
              </a>
            </NavigationMenuItem>
            <NavigationMenuItem className="px-5">
              <a href={ROUTES.SEARCH_API} legacyBehavior passHref>
                <NavigationMenuLink
                  className={
                    (navigationMenuTriggerStyle(),
                    ` ${
                      scrolled || !transperentHeader?.includes(pathname)
                        ? "text-dark-gray-1 font-semibold"
                        : "text-white"
                    } bg-transparent hover:bg-transparent text-lg hover:underline`)
                  }
                >
                  APIs
                </NavigationMenuLink>
              </a>
            </NavigationMenuItem>

            <a
              href={ROUTES?.CONTACT_US}
              className="bg-[#0d6efd] px-[24px] py-2 !ml-6 text-lg rounded-[8px] text-white"
            >
              Contact Us
            </a>
          </NavigationMenuList>
        </NavigationMenu>

        <Sheet
          open={isSheetOpen}
          className="!z-[10300] lg:hidden"
          onOpenChange={setIsSheetOpen}
        >
          <SheetTrigger asChild>
            <button
              className="outline-none border-none lg:hidden"
              onClick={() => {
                setIsSheetOpen(true);
              }}
            >
              <AlignLeft
                className={`h-8 w-8 ${
                  scrolled || !transperentHeader?.includes(pathname)
                    ? "text-black"
                    : "text-white"
                }`}
              />
            </button>
          </SheetTrigger>
          <SheetContent>
            <SheetHeader>
              <SheetTitle>
                <Logo />
              </SheetTitle>
            </SheetHeader>
            <SheetDescription></SheetDescription>
            <div className="grid gap-4 py-4 mt-7">
              <ul className="space-y-2">
                <a
                  className="flex items-center space-x-2 py-2"
                  href={ROUTES?.HOME}
                >
                  <div className="text-gray-500">Home</div>
                </a>
                <a
                  className="flex items-center space-x-2 py-2"
                  href="/#features"
                >
                  <div className="text-gray-500 ">Features</div>
                </a>
                <a className="flex items-center space-x-2 py-2" href="/#about">
                  <div className="text-gray-500">About Us</div>
                </a>
                <a
                  className="flex items-center space-x-2 py-2"
                  href="/#question-and-answer"
                >
                  <div className="text-gray-500">Q&A</div>
                </a>
                <a
                  className="flex items-center space-x-2 py-2"
                  href={ROUTES.SEARCH_API}
                >
                  <div className="text-gray-500">APIs</div>
                </a>
                <a
                  className="flex items-center space-x-2 py-2"
                  href={ROUTES?.CONTACT_US}
                >
                  <div className="text-gray-500">Contact Us</div>
                </a>
              </ul>
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </div>
  );
};
export default Header;
