import { ChevronRight } from "lucide-react";
import ROUTES from "../services/AppConstant";
import { ABOUTUS_ARRAY, FEATURES_ARRAY, QUESTION_ARRY } from "../lib/utils";

export default function Home() {
  return (
    <div>
      <section id="home">
        <div className="bg-home-bg pt-20 h-full w-full overflow-x-hidden mb-[50px] px-[15px] xl:px-0">
          <div className="flex sm:relative max-w-7xl m-auto sm:pt-20 sm:flex-row flex-col pt-10 items-center">
            <div className="h-full w-full sm:min-w-[630px] lg:pr-[20px] flex flex-wrap items-start break-words  md:px-0 ">
              <div className="mb-[20px]">
                <p className="sm:text-[3.80rem] text-[25px] leading-normal relative font-bold text-white w-full mb-[6px] sm:text-start text-center">
                  Source international books for your customers through us.
                </p>
                <p className="lg:text-2xl sm:text-lg text-sm text-white sm:text-start  text-center">
                  E-commerce websites can now source over 15 million titles from
                  2,500 vendors across more than 5 countries.
                </p>
              </div>
              <div className="flex justify-center mt-1 mb-[40px]">
                <div className="text-white flex space-x-16">
                  <div className="text-center flex sm:items-start flex-col sm:flex-row justify-center items-center">
                    <div className="text-center flex items-end">
                      <div className="md:text-5xl text-3xl font-bold leading-none">
                        3
                      </div>
                      <div className="md:text-[2rem] font-bold leading-none">
                        x
                      </div>
                    </div>
                    <div className="text-lg pl-1 ">Shorter delivery time</div>
                  </div>
                  <div className="text-center flex sm:items-start flex-col sm:flex-row justify-center items-center">
                    <div className="md:text-5xl text-3xl  font-bold">1000%</div>
                    <div className="text-lg pl-1">
                      Large selection for your home customers
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex md:justify-start justify-center w-full sm:mb-0 mb-5">
                <a
                  href={ROUTES?.SEARCH_API}
                  className="px-[30px] py-[10px] bg-blue-500 text-white  rounded-[4px] hover:bg-transparent hover:border-2 hover:border-white"
                >
                  <p className="text-lg">SEARCH API</p>
                </a>
              </div>
            </div>
            <div className="h-full w-full">
              <img
                src="/images/home/tablet-01.png"
                alt="tablet"
                className="w-auto sm:max-w-[1300px]"
                height={770}
                width={1200}
              />
            </div>
          </div>
        </div>
      </section>

      {/* ----section2---- */}
      <section id="features">
        <div className="pt-[50px]">
          <div className="max-w-7xl m-auto">
            <div className="flex justify-center ">
              <div className="text-center">
                <div className="text-[1.1rem] font-semibold tracking-wider text-gray-700 uppercase">
                  Endless Possibilities
                </div>
                <h1 className="md:text-[46px] l text-3xl font-semibold text-gray-900 mt-[30px] max-w-[700px]">
                  Source books from around the world
                </h1>
                <p className="md:text-[24px] text-xl text-gray-600 mt-4">
                  We operate 24/7 across multiple time zones
                </p>
              </div>
            </div>

            <div className="md:mt-[60px] ">
              <div className="w-full flex flex-wrap justify-center items-start">
                {FEATURES_ARRAY?.map((list, i) => {
                  return (
                    <div
                      className="sm:w-1/2 px-2 flex flex-col md:flex-row md:items-start items-center justify-center mb-4"
                      key={i}
                    >
                      <div className="h-full ">
                        <img
                          src={list?.icon}
                          alt=""
                          height={100}
                          width={100}
                          className="min-w-[135px] min-h-[135px]"
                        />
                      </div>
                      <div>
                        <div className="text-black font-bold text-[25px] mb-[10px] text-center md:text-start">
                          {list?.title}
                        </div>
                        <div className="text-dark-gray text-[1rem] text-center md:text-start">
                          {list?.description}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ----section3---- */}

      <div className="max-w-7xl m-auto bg-home-section2 mt-[50px] rounded-xl ">
        <div className="lg:px-[70px] px-[15px] pt-[80px] pb-[80px]">
          <div className="flex max-w-7xl m-auto flex-col-reverse md:flex-row  relative items-center justify-center">
            <div className="sm:w-1/2">
              <p className="text-dark-gray-1 text-[15px] mb-[35px] uppercase">
                A System That Works
              </p>
              <div>
                <p className="text-dark-gray-1 xl:text-5xl sm:text-4xl text-3xl mb-[26px]">
                  Over 1 million books shipped to more than 50 countries every
                  year.
                </p>
              </div>
              <div>
                <div className="flex items-start mb-[18px]">
                  <div className="min-w-[6px] min-h-[6px] bg-dark-gray-1 rounded-full mt-[8px]"></div>
                  <p className="ml-[15px]">
                    Join us and become part of a system that handles all your
                    orders from around the globe.
                  </p>
                </div>
                <div className="flex items-start mb-[18px]">
                  <div className="min-w-[6px] min-h-[6px] bg-dark-gray-1 rounded-full mt-[8px]"></div>
                  <p className="ml-[15px]">
                    This is an invite-only service, where we exclusively partner
                    with one or two stores in underrepresented countries.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <img
                src="/images/home/strategyBanner.png"
                alt=""
                height={652}
                width={575}
                className="xl:max-w-[675px]"
              />
            </div>
          </div>
        </div>
      </div>

      {/* ----section4---- */}

      <div className="max-w-7xl m-auto  mt-[100px] rounded-xl pb-[85px]">
        <div className="flex flex-col-reverse sm:flex-row max-w-7xl m-auto relative items-center justify-center">
          <div>
            <img
              src="/images/home/engageBanner.png"
              alt=""
              height={652}
              width={575}
              className="xl:max-w-[675px]"
            />
          </div>
          <div className="sm:w-[80%]  lg:w-1/2 ml-[30px] ">
            <p className="text-dark-gray-1 text-[15px] mb-[35px] uppercase">
              Organized Sourcing
            </p>
            <div>
              <p className="text-dark-gray-1 lg:text-5xl  sm:text-4xl text-3xl  mb-[26px]">
                Easily connect your e-commerce store with our APIs
              </p>
              <p className="text-dark-gray-1 text-lg mb-[1rem]">
                It's all about bookselling. Discuss and set up an account to
                access our APIs—it's fast and easy.
              </p>
            </div>
            <div>
              <p className="mb-[20px] text-dark-gray-1 text-[27px] font-bold">
                Get More Done in Less Time
              </p>
              <div className="flex items-start mb-[18px]">
                <div className="min-w-[6px] min-h-[6px] bg-dark-gray-1 rounded-full mt-[8px]"></div>
                <p className="ml-[15px]">
                  Search API - Initiate a search with our API when no results
                  are found, or run a parallel search. Provide your customers
                  with real-time price and availability information.
                </p>
              </div>
              <div className="flex items-start mb-[18px]">
                <div className="min-w-[6px] min-h-[6px] bg-dark-gray-1 rounded-full mt-[8px]"></div>
                <p className="ml-[15px] text-dark-gray-1">
                  Order API - Keep submitting your orders with your order number
                  to receive consolidated deliveries as quickly as every day.
                </p>
              </div>
              <div className="flex items-start mb-[18px]">
                <div className="min-w-[6px] min-h-[6px] bg-dark-gray-1 rounded-full mt-[8px]"></div>
                <p className="ml-[15px] text-dark-gray-1">
                  No email ordering. No form submissions. No hassle.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ----How it work section---- */}
      <div className="max-w-7xl m-auto relative pb-[80px]">
        <div className="md:p-[15px] p-[8px] flex justify-center">
          <div>
            <p className="text-dark-gray-1 md:text-[2.875rem] text-4xl font-semibold text-center">
              How It Works?
            </p>
            <div className="flex justify-center w-full mt-[18px]">
              <p className="text-dark-gray-1 text-[1.3125rem]  text-center sm:w-[60%] ">
                For example, let's consider Austria as your home country. An
                e-commerce store looking to source foreign books can easily have
                them delivered to their fulfillment hub in Vienna.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap lg:mt-[75px] mt-[40px]">
          <div className="text-center justify-center flex-[0,0,auto] lg:w-[33.33%] sm:w-[50%] px-2 mb-[30px]">
            <div className="pt-[42px] flex justify-center rounded-t-[12px] mb-[60px] mx-[8px] bg-gradient-purple">
              <img
                src="/images/home/howitwork1.png"
                alt=""
                className="w-auto sm:max-h-[200px]"
                height={175}
                width={272}
              />
            </div>
            <div className="text-center">
              <h6 className=" text-dark-gray-1 text-[25px] mb-[15px] font-bold">
                Foreign Books to Austria
              </h6>
              <p className="text-lg text-dark-gray-1">
                Foreign books refer to titles published outside Austria. The
                Search API provides a list of available books, and the bookstore
                continuously places orders through the API.
              </p>
            </div>
          </div>
          <div className="text-center justify-center flex-[0,0,auto] lg:w-[33.33%] sm:w-[50%] px-2 mb-[30px]">
            <div className="pt-[42px] flex justify-center rounded-t-[12px] mb-[60px] mx-[8px] bg-gradient-purple">
              <img
                src="/images/home/howitwork2.jpg"
                alt=""
                className="w-auto sm:max-h-[200px]"
                height={175}
                width={272}
              />
            </div>
            <div className="text-center">
              <h6 className=" text-dark-gray-1 text-[25px] mb-[15px] font-bold">
                International Priority Delivery
              </h6>
              <p className="text-lg text-dark-gray-1">
                Books sourced from various shipping countries are packed and
                boxed for delivery to Vienna.
              </p>
            </div>
          </div>
          <div className="text-center justify-center flex-[0,0,auto] lg:w-[33.33%] sm:w-[50%] px-2 mb-[30px]">
            <div className="pt-[42px] flex justify-center rounded-t-[12px] mb-[60px] mx-[8px] bg-gradient-purple">
              <img
                src="/images/home/howitwork3.jpg"
                alt=""
                className="w-auto sm:max-h-[200px]"
                height={175}
                width={272}
              />
            </div>
            <div className="text-center">
              <h6 className=" text-dark-gray-1 text-[25px] mb-[15px] font-bold">
                Prepacked Orders
              </h6>
              <p className="text-lg text-dark-gray-1">
                Submit your 4x6 thermal shipping label, and we'll label the
                package for you. Delivery frequency can be adjusted based on
                your order volume.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* ---- About us ---- */}
      <section id="about">
        <div className="md:py-[85px]">
          <div className="sm:max-w-7xl m-auto relative flex lg:flex-row flex-col">
            <div className="sm:w-[42%] sm:min-w-[550px] w-full">
              <div className="items-center p-[15px] flex relative flex-wrap w-full">
                <div className="mb-[20px]">
                  <h2 className="font-bold text-dark-gray-1 md:text-[53px] text-3xl mb-[50px]">
                    About us
                  </h2>
                  <p className="mb:text-[24px] mb-[18px] text-dark-gray-1 text-xl">
                    We are a family-owned business with over 50 years of
                    bookselling experience. Our well- established processes and
                    systems have been refined over the years and are now
                    accessible through our APIs.
                  </p>
                  <div className="flex items-start mb-[18px]">
                    <div className="min-w-[6px] min-h-[6px] bg-dark-gray-1 rounded-full mt-[8px]"></div>
                    <p className="ml-[15px] text-lg text-dark-gray-1">
                      With an existing presence in five major markets, we
                      continue to expand into new countries and partner with
                      more publishers each year.
                    </p>
                  </div>
                </div>
                <div>
                  <a href={ROUTES?.SEARCH_API} className="">
                    <div className="flex items-center">
                      <p className="text-[18px] text-blue-600">
                        Get started - Let us connect{" "}
                      </p>
                      <div>
                        <ChevronRight className="text-blue-600 pt-1 ml-3" />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap ">
              {ABOUTUS_ARRAY?.map((list, i) => {
                return (
                  <div className="md:w-1/2 xl:px-[13px] px-[10px]" key={i}>
                    <div className="xl:p-[35px] p-[25px] bg-white mb-[30px] text-start shadow-AboutUs rounded-[12px]">
                      <div className="h-full flex justify-center sm:justify-start">
                        <img
                          src={list?.icon}
                          alt=""
                          height={100}
                          width={100}
                          className="min-w-[135px] min-h-[135px]"
                        />
                      </div>
                      <div>
                        <div className="text-black font-bold text-[25px] mb-[10px] sm:text-start text-center">
                          {list?.title}
                        </div>
                        <div className="text-dark-gray text-[1rem] sm:text-start text-center">
                          {list?.description}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* ----Quesions ---- */}
      <section id="question-and-answer">
        <div className="max-w-7xl m-auto pt-9 md:pt-[85px] px-[15px] xl:px-0">
          <div className="flex w-full justify-center mb-[70px]">
            <div>
              <p className="font-bold md:text-[3.125rem] text-4xl text-dark-gray-1 text-center">
                Questions & Answers
              </p>
              <p className="mt-[18px] text-light-gray-1 text-center w-full text-[24px]">
                Check if your question is answered below. If not, feel free to
                <a href={ROUTES?.CONTACT_US} className="text-blue-600">
                  {" "}
                  contact us.
                </a>
              </p>
            </div>
          </div>
          <div className="w-full flex flex-wrap text-left gap-[30px]">
            {QUESTION_ARRY?.map((list, i) => {
              return (
                <div className="lg:w-[48%] flex flex-wrap" key={i}>
                  <div className="flex flex-col">
                    <p className="md:text-[25px] text-xl  mb-[18px] text-dark-gray-1">
                      {list?.count}. {list?.question}
                    </p>
                    <p className="text-light-gray-1 md:text-lg text-base mb-[18px]">
                      {list?.answer}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex justify-center mt-[40px]">
            <div className="bg-sky md:px-[46px] px-[20px] py-[22px] rounded-[100px] flex">
              <div className="text-[18px]">Any questions?</div>
              <a
                href={ROUTES?.CONTACT_US}
                className=" text-[18px] text-blue-600 pl-1"
              >
                Get in Touch
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
