import { useState } from "react";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { API_CONSTANT } from "../services/ApiConstant";
import { postWithOutToken } from "../services/ApiService";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setLoading(true);
    const obj = {
      AppUserId: 0,
      Name: data?.name,
      IPAddress: "",
      PhoneNumber: data?.phone,
      EmailAddress: data?.email,
      QueryInDetail: data?.question,
    };

    postWithOutToken(`${API_CONSTANT?.CONTACT_US}`, obj)
      .then((res) => {
        toast.success(res?.data?.Message);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <div className="md:pt-40 pt-28 text-left">
      <div className="max-w-7xl m-auto p-[15px] ">
        <div className="md:w-[79.332%] m-auto mb-10">
          <div className="flex justify-center w-full">
            <p className="md:text-[53px] text-4xl font-semibold text-dark-gray-1 text-center">
              Interested? Let's Talk
            </p>
          </div>
          <div className="mt-[18px] ">
            <p className="text-xl md:text-[22px]  text-dark-gray-1 text-center">
              Want to discuss more about Spondees, to help sourcing foreign
              books. Let us know your current volumes and arrangements, issues
              faced and we can discuss the advantages we can bring in.
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-7xl m-auto  p-[15px]">
        <div className="max-w-[600px] m-auto">
          <form onSubmit={handleSubmit(onSubmit)} className="">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-lg font-medium mb-1"
                htmlFor="name"
              >
                Your Name:
              </label>
              <p className="text-sm mb-6 font-light">
                Please enter your real name:
              </p>
              <input
                id="name"
                type="text"
                placeholder="Your Name*"
                {...register("name", { required: true })}
                className="w-full h-14 text-gray-700 px-[15px]  shadow-[0_0_0_0] rounded-[6px] focus:outline-none focus:shadow-outline bg-lightBlue"
              />
              {errors.name && (
                <p className="text-red-400 text-base ">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-lg font-medium mb-1"
                htmlFor="name"
              >
                Your Phone Number:
              </label>
              <p className="text-sm mb-6">Please enter your Phone Number:</p>
              <input
                id="phone"
                type="tel"
                placeholder="Phone Number*"
                {...register("phone", { required: true })}
                className="w-full h-14 text-gray-700 px-[15px]  shadow-[0_0_0_0] rounded-[6px] focus:outline-none focus:shadow-outline bg-lightBlue"
              />
              {errors.phone && (
                <p className="text-red-400 text-base ">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-lg font-medium mb-2"
                htmlFor="name"
              >
                Your Email Address:
              </label>
              <p className="text-sm mb-6">
                Please carefully check your email address for accuracy
              </p>
              <input
                id="email"
                type="email"
                placeholder="Email Address*"
                {...register("email", { required: true })}
                className="w-full h-14 text-gray-700 px-[15px]  shadow-[0_0_0_0] rounded-[6px] focus:outline-none focus:shadow-outline bg-lightBlue"
              />
              {errors.email && (
                <p className="text-red-400 text-base ">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-lg font-medium mb-2"
                htmlFor="name"
              >
                Explain your question in details:
              </label>
              <p className="text-sm mb-6">
                {/* Your OS version, Martex version & build, steps you did. Be VERY
                precise! */}
              </p>
              <textarea
                id="question"
                rows={4}
                placeholder="Our Ecommerce store name is  "
                {...register("question", { required: false })}
                className="w-full px-3 py-2 text-gray-700  shadow-[0_0_0_0] rounded-[6px]  focus:outline-none focus:shadow-outline bg-lightBlue"
              ></textarea>
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                disabled={loading}
                className="bg-blue-600 text-[1.2rem] mt-[15px] hover:bg-blue-700 text-white  py-3 px-10 rounded focus:outline-none focus:shadow-outline"
              >
                {loading ? (
                  <Loader2 className="h-6 w-6 animate-spin mx-5" />
                ) : (
                  "Submit Request"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
