import React from "react";
const Spinner = ({ spinnerClass }) => {
  return (
    <div>
      <div className="flex justify-center w-full h-16 items-center relative">
        {[...Array(12)].map((_, i) => (
          <div
            key={i}
            className={`${spinnerClass} w-3 h-3 bg-white rounded-full absolute animate-fade`}
            style={{
              transform: `rotate(${i * 30}deg) translate(30px)`,
              animationDelay: `${i * 0.1}s`,
            }}
          />
        ))}
      </div>
      <div className="flex items-center text-xl text-white justify-center w-full mt-[62px]">
        Please wait
      </div>
    </div>
  );
};
export default Spinner;
