import axios from 'axios'
import { API_CONSTANT } from './ApiConstant'

const api = axios.create({
  timeout: 20000,
  baseURL: API_CONSTANT?.BASE_URL,
})

// api.interceptors.request.use(
//   async (_config) => {
//     try {
//       if (_config?.url?.includes("unauthorized")) return _config;
//       if (getFromAsync(SESSION_KEY?.ACCESS_TOKEN)) {
//         _config.headers.Authorization = getAccessToken();
//         // dispatch(setIsAuthenticated(true));
//       }
//       return _config;
//     } catch (error) {}
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

// for multiple requests
// let isRefreshing = false;
// let failedQueue = [];

// const processQueue = (error, token = null) => {
//   failedQueue.forEach((prom) => {
//     if (error) {
//       prom.reject(error);
//     } else {
//       prom.resolve(token);
//     }
//   });

//   failedQueue = [];
// };

// api.interceptors.response.use(undefined, interceptor(api));

// const removeToken = async () => {
//   await removeFromAsync(SESSION_KEY.REFRESH_TOKEN);
//   await removeFromAsync(SESSION_KEY.ACCESS_TOKEN);
// };

// const get = async (endPoint) => {
//   return new Promise(async (resolve, reject) => {
//     api
//       .get(`${endPoint}`)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch(async (err) => {
//         reject(err);
//       });
//   });
// };

// const getWithOutToken = (endPoint) => {
//   return new Promise((resolve, reject) => {
//     api
//       .get(`${endPoint}`)
//       .then((res) => resolve(res))
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// const post = (endPoint, data) => {
//   return new Promise((resolve, reject) => {
//     api
//       .post(`${endPoint}`, data)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// const postWithTokenAndFormHeader = async (endPoint, data = {}) => {
//   return new Promise(async (resolve, reject) => {
//     const headerWithFormData = {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "multipart/form-data",
//       },
//     };
//     api
//       .post(`${endPoint}`, data, headerWithFormData)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

const postWithOutToken = (endPoint, data = {}) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${endPoint}`, data)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

// const removePostComment = (endPoint, postData = {}) => {
//   return new Promise(async (resolve, reject) => {
//     var config = {
//       method: "delete",
//       url: `${API_CONSTANT.BASE_URL}${endPoint}`,
//       headers: {
//         Authorization: getAccessToken(),
//         "Content-Type": "application/json",
//       },
//       data: postData,
//     };
//     axios(config)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export {
  //   get,
  //   post,
  //   postWithTokenAndFormHeader,
  postWithOutToken,
  //   getWithOutToken,
  //   getAccessToken,
  //   getRefereshToken,
  //   removePostComment,
  //   removeToken,
}
