import { Logo } from "./ui/icons";
import ROUTES from "../services/AppConstant";

const Footer = () => {
  return (
    <div>
      <div className="max-w-7xl m-auto pt-[100px] pb-3 px-[15px] xl:px-0">
        <div className="flex-wrap flex items-start justify-between">
          <div className="flex flex-wrap  md:w-[80%] w-full">
            <div className="lg:w-[50%] pr-7 ">
              <div className="mb-[1rem]">
                <Logo className={"h-[70px] w-[250px]"} />
              </div>
              <p className="mb-[1rem] text-lg text-dark-gray-1 text-left">
                Source foreign books through us.
              </p>
            </div>
            <div className="lg:w-[17.66%] mt-4 md:mt-0">
              <div className="text-[19px] text-dark-gray-1 font-bold mb-[1rem] h-[58px] flex items-center">
                Connect With Us
              </div>
              <div className="text-[16px] mb-[10px]">support@spondees.com</div>
            </div>
            {/* <div className="md:w-[50%] md:pt-2">
              <div className=" text-[19px] text-dark-gray-1 font-bold mb-[25px]">
                Company
              </div>
              <a href={ROUTES?.CONTACT_US} className="text-[16px] mb-[10px]">
                Contact Us
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <footer className="bg-gray-800 text-white py-12 mt-10 px-[15px] xl:px-0">
        <div className="max-w-7xl mx-auto flex justify-between sm:items-center sm:flex-row flex-col">
          <span className="text-lg">
            Copyright © 2024 Spondees. All rights reserved
          </span>
          <div className="flex sm:items-center space-x-4 items-start sm:mt-0 mt-2">
            <a href={ROUTES?.CONTACT_US} className="text-lg">
              Contact Us
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
