import { Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import ROUTES from "../services/AppConstant";
import ContactUs from "../pages/contactUs";
import SearchApi from "../pages/searchApi";

function Navigation() {
  return (
    <Routes>
      <Route path={ROUTES.HOME} element={<Home />} />
      <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
      <Route path={ROUTES.SEARCH_API} element={<SearchApi />} />
    </Routes>
  );
}

export default Navigation;
